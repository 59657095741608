import React, { useState, useEffect, useContext } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import { Translate } from "react-auto-translate";
import PolyLogo from "../../assets/animation/Poker.png";
import complete from "../../assets/animation/checked.json";
import confetti from "../../assets/animation/win.json";
import toast from "react-hot-toast";
import CryptoNOT from "../cryptoNOT/index";
import { gapi } from 'gapi-script';
import Lottie from 'react-lottie';
import "./index.css";
import axios from 'axios';
import UrlConfigs from "../../utils/ApiConfig.js";
import { MainContext } from '../../context';

const aniChecked = {
    loop: true,
    autoplay: true,
    animationData: complete,
};

const aniConfetti = {
    loop: true,
    autoplay: true,
    animationData: confetti,
};

const SignInWithGoogle = ({ onClose, profileConnected, onSuccess }) => {
    const { firebaseUser } = useContext(MainContext);
    const [gapiLoaded, setGapiLoaded] = useState(false);
    const [walletTransfer, setwalletTransfer] = useState(localStorage.getItem('walletid'));
    const [walletConnected, setWalletConnected] = useState(localStorage.getItem("isWalletConnected") === 'true' || profileConnected); // Convert to boolean
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setisSuccess] = useState(false);
    const [hashtags, sethashtags] = useState("");

        useEffect(() => {
            const initClient = () => {
                gapi.client.init({
                    clientId: "198324805267-dubhan7gqqp93t9r2i76eb3e2hism8h7.apps.googleusercontent.com",
                    scope: 'email',
                }).then(() => {
                    setGapiLoaded(true);
                }).catch(error => {
                    console.error("Error initializing Google Auth", error);
                });
            };
            gapi.load('client:auth2', initClient);
        }, []);

        const handleAddGmailConnect = async () => { 
            if (!walletConnected) {
                toast.error(<span><Translate>Your crypto wallet is disconnected</Translate></span>, {
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                    },
                });
                return; 
            }
            if (gapiLoaded) {
                setIsLoading(true);
                try {
                    const auth2 = gapi.auth2.getAuthInstance();
                    const googleUser = await auth2.signIn();
                    const token = googleUser.getAuthResponse().id_token;
                 
                    try {
                        const res = await axios.get(UrlConfigs.addEmail, {
                            headers: {
                                banana:token,
                            },
                            withCredentials:true,
                            credentials: "include"
                        });

                        // is User already subscribed user.isEmailVerfied = true return 
                        // 
                        console.log("Response:", res.data);
                        if (res.data.error === 'no error') {
                            setIsLoading(false);
                            setisSuccess(true);
                            onSuccess(); 
                            console.log("Email successfully added. Hash:", res.data.hash);
                            sethashtags(res.data.hash);
                        } else if (res.data.error === 'email already exists') {
                            toast.error(<span><Translate>The email address is already being used</Translate></span>, {
                                style: {
                                    duration: 4000,
                                    maxWidth: 800,
                                    fontSize: "14px",
                                    borderRadius: "15px",
                                },
                            });
                            setIsLoading(false);
                            setisSuccess(false);
        
                        } else {
                            toast.error(<span><Translate>Failed to add email</Translate></span>, {
                                style: {
                                    maxWidth: 800,
                                    fontSize: "14px",
                                    borderRadius: "15px",
                                },
                            });
                            console.error("Failed to add email:", res.data.error);
                            setIsLoading(false); // Reset
                            setisSuccess(false);
                        }
                    } catch (apiError) {
                        console.error("Error adding email to API", apiError);
                        setIsLoading(false); // Reset
                        setisSuccess(false);
                    }
                } catch (signInError) {
                    console.error("Error signing in with Google", signInError);
                    if (signInError.error === 'popup_closed_by_user') {
                        setIsLoading(false); // Reset
                        setisSuccess(false);
                    }
                }
            } else {
                console.error("Google API not loaded yet");
            }
        };
    

        if (!walletConnected) {
            return <CryptoNOT />;
        } else
        return (
            <>
              <Modal
                show={true}
                onHide={onClose}
                centered
                className="invite-friends-popup gmail-popup" >
                <div className="lottie-background">
                    <Lottie options={aniConfetti} width={500} height={550} />
                </div>
                    <Modal.Header closeButton>
                        <Modal.Header>
                            {!isLoading && !isSuccess ? (
                                        <Modal.Title className='title-gmail'>
                                            <h5 className="free-pol-h5">
                                                <b className='b-free-pol'>
                                                    <Translate>Get</Translate> 5 POL
                                                </b>
                                                <Translate> after your first game!</Translate>
                                                <img
                                                    src={PolyLogo}
                                                    alt="BlackJack"
                                                    className="pol-coin-gmail"
                                                />
                                            </h5>
                                        </Modal.Title>
                                    ) : null}
                                </Modal.Header>
                            </Modal.Header>

                            <Modal.Body className='modal-gmail'>
                                    <div className="invite-friends-text">
                                    {!isLoading && !isSuccess ? (
                                        <p>
                                            <Translate>
                                                Subscribe to our newsletter and get 
                                            </Translate>
                                            <b className='b-text-gmail'> 5 POL </b> 
                                            <Translate>
                                                to play your favorite games 
                                            </Translate>
                                        </p>
                                    ) : isLoading ? (
                                        <p className='verify-account'>
                                            <Spinner animation="border" className='spinner-gmail'/>
                                            <Translate>
                                                Verify your Google account ..
                                            </Translate>
                                        </p>                                
                                    ) : isSuccess ? (
                                        <div className='hash-gmail'>
                                            <p>
                                            <Translate>
                                                Funds have been transferred to your wallet
                                            </Translate>
                                            </p>
                                            <Lottie options={aniChecked} width={100} height={100} />                           
                                        </div>
                                    ) : null}
                                    <>
                                    <div className='gmail-section'>
                                            {!isLoading && !isSuccess ? (
                                                <button
                                                    className="login-with-google-btn"
                                                    onClick={handleAddGmailConnect}
                                                    disabled={!walletConnected || isLoading}>
                                                    <Translate>
                                                        Subscribe with
                                                    </Translate> Gmail
                                                </button>
                                            ) : null}
                                    </div>  
                                </>
                            </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    };

export default SignInWithGoogle;