import React, { useState, useEffect } from "react";
import ContactForm from "./SMTP";
import { Helmet } from "react-helmet";

const Investment = () => {
  const texts = [
    "DeFi Social Gaming",
    "Innovative Sports System",
    "Early-Stage Opportunity",
    "Tokenized Ecosystem",
    "Live Video Streaming",
    "Innovative Technology",
    "A Growing Market",
  ]; 

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isShrinking, setIsShrinking] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Start shrinking animation
      setIsShrinking(true);
        setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsShrinking(false); // Start expanding animation
      }, 500); 
    }, 2500);
  
    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <div className="tabbedcontent">
      <Helmet>
      <title>WEJE | Exploring Investment Opportunities in DeFi Social Gaming</title>
      <meta
        name="description"
        content="Discover investment opportunities in WEJE’s innovative DeFi social gaming platform. Be part of the future of decentralized gaming and enjoy the potential of blockchain-based projects in the gaming and sports sectors."
      />
      <meta
        name="keywords"
        content="Web3 innovation, blockchain gaming, DeFi opportunities, decentralized gaming, blockchain technology, gaming startups, blockchain advancements, digital asset projects, crypto industry growth, decentralized finance opportunities"
      />

      </Helmet>

      <h4>
        <b>Thinking to invest? </b>
         Let’s schedule a
        meeting and discuss —{" "}
        <a className="email-invest">Support@weje.com</a>
      </h4>

      <div className="container">
        <div className="row">
          <div className="col-xs-12 crypto-view">
            <div className="card-crypto">
              <div className="card-body">

                <div className="i-div">
                  <h5 className="i-div-h5">Why investing in Weje.com?</h5>
                  <h4
                    className={`i-h5 animate-row ${
                      isShrinking ? "shrink" : "expand"
                    }`} >
                    <span>{texts[currentIndex]}</span>
                  </h4>
                </div>

                <ul>
                  <li>
                    <strong>DeFi Social Gaming: &nbsp;</strong>
                    Investing in the future of Web3 gaming found you interesting? 
                    Imagine a global social platform that merges skill-based online games with cutting-edge decentralized finance (DeFi) technology. 
                    This innovative system empowers players to elevate their gaming experience through strategic gameplay, token ownership, and meaningful social interactions—all within a secure, community-driven ecosystem.
                  </li>
                  <li>
                    <strong>Innovative Sports System: &nbsp;</strong> 
                    Sports bring people and communities together, creating moments of unity and excitement. 
                    WEJE takes this thrill to the next level with its innovative Web3-powered crypto sports event system. 
                    This platform allows unlimited users to participate in a single sports ticket, breaking barriers and unlocking limitless potential.
                    Imagine the excitement of a ticket that connects countless participants. The ultimate reward? The user who predicts all game outcomes correctly wins it all.
                  </li>
                  <li>
                    <strong>Early-Stage Opportunity: &nbsp;</strong>{" "}
                    Investing in early-stage ventures like WEJE provides the chance to support a growing company at the forefront of technological innovation. As WEJE expands and captures market share, it aims to deliver unique opportunities for value appreciation. By joining our journey, you can be part of a transformative platform poised to redefine gaming and social engagement.
                  </li>
                  
                  
                  <li>
                    <strong>Tokenized Ecosystem: &nbsp;</strong> 
                    We are working on creating our own digital currency, envisioned to serve as the cornerstone of the Weje platform.
                    More than just a cryptocurrency, Weje Coin is designed to empower players to become stakeholders, contributors, and beneficiaries within the platform. 
                    It aims to foster engagement and innovation while ensuring users can actively participate in the growth and success of the ecosystem.
                  </li>
                  <li>
                    <strong>Live Video Streaming: &nbsp;</strong> 
                    WEJE takes gaming interaction to the next level with live video streaming. 
                    This optional feature enables players to share their excitement in real time, and enhancing connection and transparency within the community. 
                  </li>
                  <li>
                    <strong>Innovative Technology: &nbsp;</strong> 
                    Blockchain gaming presents unique challenges, but the WEJE team has already overcome these to deliver an innovative Web3 gaming experience. 
                    We leverage Google Cloud's scalable infrastructure with auto-scaling to handle high traffic, and use Node.js for optimized gaming server performance.
                    Our Progressive Web App (PWA) unifies all games into a single, advanced social gaming platform, offering a smooth and engaging experience for all users.
                  </li>
                  <li>
                    <strong>A Growing Market: &nbsp;</strong> 
                    The blockchain gaming industry is reshaping the global gaming market, valued at over $200 billion. 
                    With a focus on decentralized finance (DeFi), true ownership of in-game assets, and community-driven engagement, WEJE is positioned to leverage this rapid growth.
                  </li>
                </ul>

                <h5>Conclusion: &nbsp;</h5>
                <p>
                The blockchain gaming industry is evolving, and WEJE is at the forefront of this exciting opportunity. 
                If investing in WEJE find you interesting, and you think to join our journey in shaping the future of social gaming, we’d love to connect with you.
                Please send us your details and lets have a Let's have a call.
                </p>

                <div className="form-component">
                  <ContactForm />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investment;
