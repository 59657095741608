import { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { Link } from "react-router-dom";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Investment from "./Investment";
import Trump from "./Trump";
import "./help-center.css";
import { useParams } from "react-router-dom";

const HelpCenter = () => {
  const { tab } = useParams();
  const [key, setKey] = useState(0);
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [key]);

  useEffect(() => {
      
    if (tab) {
      if (tab === "investment") {
        setKey(0);
      } else if (tab === "trump") {
        setKey(1);
      } else {
        setKey(0);
      }
    }
  }, [tab]);


  return (
    <Layout>
      <>
        <div className="help-center-section">
          <div className="container">
            <div className="help-center-content">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={tab || "investment"} >
                <Row>
                  <Col sm={2}>
                    <div id="left-tabs">
                      <Nav variant="pills" className="flex-column">

                        <Nav.Item>
                          <Nav.Link
                            to="/investment-opportunity/investment"
                            eventKey="investment" >
                              Why Invest in WEJE?
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            to="/investment-opportunity/creation"
                            eventKey="trump">
                              Now Is the Time to Invest
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                        <Nav.Link>
                          <div className="copyright-text text-center">
                              <p>© 2024 <Link to="/">WeJe</Link></p>
                          </div>
                          </Nav.Link>
                        </Nav.Item>
                        
                      </Nav>
                    </div>
                  </Col>

                  <Col sm={10}>
                    <Tab.Content>

                      <Tab.Pane eventKey="investment">
                        <Investment />
                      </Tab.Pane>

                      <Tab.Pane eventKey="trump">
                        <Trump />
                      </Tab.Pane>

                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default HelpCenter;
