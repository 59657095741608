import { useState, useEffect} from "react";
import PokerAbi from "../ABI/WejeAbi.json";
import BlackjackAbi from "../ABI/BlackjackAbi.json";
import LudoAbi from "../ABI/LudoAbi.json";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";


const useEventListener = (firebaseUser) => {
    const [link, setLink] = useState("");
    const { provider, account } = useWeb3React()
    useEffect(() => {
        if(!provider || !firebaseUser)
            return;
        const signer = provider.getSigner();
        let pokerContract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, PokerAbi, signer);
        let blackjackContract = new ethers.Contract(process.env.REACT_APP_BLACKJACK_CONTRACT_ADDRESS, BlackjackAbi, signer);
        let ludoContract = new ethers.Contract(process.env.REACT_APP_LUDO_CONTRACT_ADDRESS, LudoAbi, signer);

        pokerContract.on("GameCreated",async(gameId) => {
            const game = await pokerContract.getGameById(gameId);
            if(game && game.players.find(el => el.playerId === firebaseUser.uid)){
                setLink(`/poker/index.html?tableid=${gameId}&gameCollection=${game.gameType}`)
            }
        });
        pokerContract.on("PlayerJoined",(gameId, playerId) => {
            if(playerId === firebaseUser.uid){
            setLink(`/poker/index.html?tableid=${gameId}&gameCollection=pokerCP_Tables`)
            }
        });
        blackjackContract.on("GameCreated",async(gameId) => {
            const game = await blackjackContract.getGameById(gameId);
            if(game && game.players.find(el => el.playerId === firebaseUser.uid)){
                setLink(`/poker/index.html?tableid=${gameId}&gameCollection=BlackJack_Tables`)
            }
            
        });
        blackjackContract.on("PlayerJoined",(gameId, playerId) => {
            if(playerId === firebaseUser.uid){
            setLink(`/poker/index.html?tableid=${gameId}&gameCollection=BlackJack_Tables`)
            }
        });

        ludoContract.on("GameCreated",async(gameId) => {
            console.log("gameID", gameId)
            const game = await ludoContract.getGameById(gameId);
            if(game && game.players.find(el => el.playerId === firebaseUser.uid)){
                setLink(`/ludo/index.html?tableid=${gameId}&gameCollection=${game.gameType}`)
            }
            
        });
        ludoContract.on("PlayerJoined",async(gameId, playerId) => {
            if(playerId === firebaseUser.uid){
             const game = await ludoContract.getGameById(gameId);
            setLink(`/ludo/index.html?tableid=${gameId}&gameCollection=${game.gameType}`)
            }
        });
        return () => {
            blackjackContract.off("PlayerJoined")
            blackjackContract.off("GameCreated")
            pokerContract.off("GameCreated");
            pokerContract.off("PlayerJoined");
            ludoContract.off("GameCreated");
            ludoContract.off("PlayerJoined");
        }
    },[provider, firebaseUser])
    return { link, account}
}

export default useEventListener;