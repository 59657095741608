import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import BetSmartDesktop from "../../assets/images/gifs/BetSmart.gif";
import BetPokerDesktop from "../../assets/images/gifs/BetPoker.gif";
import BetBlackJackDesktop from "../../assets/images/gifs/BetBlackJack.gif";
import BetSmartMobile from "../../assets/images/gifs/BetSmart.gif";
import BetPokerMobile from "../../assets/images/gifs/BetPoker.gif";
import BetBlackJackMobile from "../../assets/images/gifs/BetBlackJack.gif";

import logo from "../../assets/images/Weje-logo.png";
import registerplayImage from "../../assets/images/register_play.png";
import registerEmptyImage from "../../assets/images/register_empty.png";
import nft from "../../assets/images/nft.png";
import brazil from "../../assets/images/icons/brazil.png";
import india from "../../assets/images/icons/india.png";
import mexico from "../../assets/images/icons/mexico.png";
import china from "../../assets/images/icons/china.png";
import japan from "../../assets/images/icons/japan.png";
import PolygonIcon from "../../assets/images/polygon-icon.png";
import image1 from "../../assets/images/nftPandas/panda1.png";
import image2 from "../../assets/images/nftPandas/panda2.png";
import image3 from "../../assets/images/nftPandas/panda3.png";
import image4 from "../../assets/images/nftPandas/panda4.png";
import image5 from "../../assets/images/nftPandas/panda5.png";
import image6 from "../../assets/images/nftPandas/panda6.png";
import image7 from "../../assets/images/nftPandas/panda7.png";
import image8 from "../../assets/images/nftPandas/panda8.png";
import image9 from "../../assets/images/nftPandas/panda9.png"; 
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import Register from "./register";
import { Translate } from "react-auto-translate";
import VideoComponent from "../videos/video.jsx";
import "./index.css";

function Landing() {

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [activeClass, setActiveClass] = useState('');
    const [isVideoPlaying, setIsVideoPlaying] = useState(false); 
    const [RegisterShow, setRegisterShow] = useState(false); 
    const [NFTPopup, setNFTPopup] = useState(false); 

    const formatPercentage = (value) => '$' + `${value.toFixed(1)}T`;
    const formatWithCommas = (value) =>
    new Intl.NumberFormat("en-US").format(Math.round(value));

    const Counter = ({ to = 0, speed = 1500, format }) => {
        const [value, setValue] = useState(0);
        useEffect(() => {
          const updateInterval = 50; // Update interval in ms
          const totalSteps = Math.ceil(speed / updateInterval);
          const increment = to / totalSteps;
          let currentStep = 0;
          const timer = setInterval(() => {
            currentStep++;
            setValue((prev) => Math.min(prev + increment, to));
            if (currentStep >= totalSteps) {
              clearInterval(timer);
              setValue(to); // Ensure exact final value
            }
          }, updateInterval);
          return () => clearInterval(timer);
        }, [to, speed]);
      
        // Format the value dynamically
        const formattedValue =
          typeof format === "function" ? format(value) : Math.round(value);
        return <h2 className="count-title">{formattedValue}</h2>;
      };

      const containerRef = useRef(null);
      useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              observer.disconnect();
            }
          },
          {
            root: null,
            threshold: 0.5,
          }
        );
        if (containerRef.current) {
          observer.observe(containerRef.current);
        }
        return () => {
          if (containerRef.current) {
            observer.unobserve(containerRef.current);
          }
        };
      }, []);

    const handleMouseEnter = (className) => {
        setActiveClass(`active-item ${className}`);
    };

    const handleMouseLeave = () => {
        setActiveClass('');
    };

    const handlePlayClick = (videoType) => {
        setIsVideoPlaying(true);
        setSelectedVideo(null); // Reset the video
        setTimeout(() => setSelectedVideo(videoType), 0);
    };

    const handleCloseVideo = () => {
        setIsVideoPlaying(false); // Reset to false
        setSelectedVideo(null);
    };

    const handleNFTPopup = () => {
        setNFTPopup(true);
    }

    const RegisterClose = () => {
        setRegisterShow(false);
    }

    const handleNFTPopupClose = () => {
        setNFTPopup(false);
    }

    const handleRegisterPopup = () => {
        setNFTPopup(false);
        setRegisterShow(true);
    }

    const games = [
        { title: "Sports Betting", subheading: "Player Vs Player", description: "Create a ticket or join an existing one and watch your bet grow! The more players that join, the bigger the pot. Turn your sports knowledge into life-changing winnings today!", image: isMobile ? BetSmartMobile : BetSmartDesktop, },
        { title: "Poker", subheading: "Live Video Chat", description: "Experience the future of online poker with WeJe. Tournaments are one of the most exciting ways to play - create your own, invite your crypto friends, showcase your skills, and win big!", image: isMobile ? BetPokerMobile : BetPokerDesktop, },
        { title: "BlackJack", subheading: "Live Video Chat", description: "Play immediately and test your BlackJack skills! Attempt to beat the dealer by getting a count as close to 21, without going over. With card counting, you can turn the odds in your favor!.", image: isMobile ? BetBlackJackMobile : BetBlackJackDesktop, },
        // { title: "Ludo", subheading: "Live Video Chat", description: "Join real players from around the world and showcase your LUDO skills to earn big! With DeFi LUDO, your winnings are automatically transferred via smart contracts after each game, ensuring full transparency and security on the blockchain.", image: registerplayImage }
    ];

    const counters = [
        {
          id: 1,
          title: "Market",
          text: "As of November 14, 2024, the global cryptocurrency market capitalization reached a record $3.2 trillion.",
          to: 3.2,
          speed: 2500,
          format: formatPercentage, // Add percentage format
        },
        {
          id: 2,
          title: "Crypto Millionaires",
          text: "The number of cryptocurrency millionaires nearly doubled in the past year, totaling 172,300 individuals.",
          to: 172300,
          speed: 2500,
          format: formatWithCommas, // Add comma-separated format
        },
        {
          id: 3,
          title: "Global Adoption",
          text: "Approximately 420 million people worldwide are estimated to own cryptocurrencies.",
          to: 420000000,
          speed: 2500,
          format: formatWithCommas, // Add comma-separated format
        },
      ];

      const nftImages = [image1, image2, image3, image4, image5, image6, image7, image8, image9];

      const Accordions = [
        { Item: "Is WeJe safe and secure?", Card: "At WeJe, we prioritize both transparency and security. If you create a game and deposit tokens into the game pool but no one joins, your tokens will be fully refunded once the game ends. Similarly, with sports betting, if you're the only participant after the first game starts, you'll receive an immediate full refund. Winnings and losses are automatically recorded live on the blockchain, and transaction logs are written to your SETTINGS with your Polygon hash tag." },
        { Item: "What is WeJe, how does it work?", Card: "WeJe is a decentralized, live video Peer-to-Peer Web3 social gaming platform powered by DeFi technology. It allows users to play their favorite games—such as Poker, Blackjack, Ludo, and sports betting—against each other in real-time. With integrated video streaming, chat features, and a vibrant community, WeJe enables players to connect, make new friends, and compete with real people from around the world." },
        { Item: "How can I start playing on WeJe?", Card: "To get started, you’ll need a crypto wallet, such as MetaMask or Coinbase Wallet, installed on your device. We ensure that no deposits, withdrawals, or registrations are required. Simply connect your wallet and click on the CREATE ACCOUNT button. Once you're in your profile, you'll find video guides in each game section that explain how to create a game. We promise it's easy to use and super fun!" },
        { Item: "Can I play with my phone device?", Card: "Yes, click on the MetaMask button using your mobile will send you to download the App crypto native application. After Installation, using the Native wallet it will open weje.com using their wallet in app browser. You can also enter weje.com manually with their browser, Once youre there click on connect wallet. WeJe natively integrate the crypto in-app browser, and you can play and accept the transaction instantly within the native crypto wallet." },
        { Item: "Which cryptocurrencies are supported on WeJe?", Card: "POL (formerly MATIC) is the native coin of the Polygon network, used for transactions within WeJe games. We are actively working on launching our own coin, which will allow the system to support both WeJe Coin and POL in the near future." },
        { Item: "I transferred coins to the smart contract, but no one joined the game. Will I lose my money?", Card: "No. If you don’t lose the game, or if no one joins your betting, your money will be refunded immediately." },
        { Item: "Does WeJe offer any rewards during games?", Card: "Yes! When you place a sports bet ticket, you earn a 2.5% commission from the total ticket pot. The more participants, the bigger your cashback reward." },
        { Item: "Do I have to use live video streaming during games?", Card: "No. You can create a non-video game, where all players around will see their avatars instead of their cameras." },
      ];


    return (
        <>
            <Helmet>
                <html lang='en' className='remove-header-padding' />
            </Helmet>
            <Layout>

                {/* main container Section */}
                <div className='login account-create'>
                    <div className='login-section'>
                        <div className='container'>
                        <div className="swiper-slide  d-flex">
                            <div className="row align-items-center main-content">
                                <div className="col-md-6">
                                    {!isVideoPlaying ? (
                                        <div 
                                            className="main-image-section" 
                                            onClick={() => handlePlayClick("register")} >
                                            <img 
                                                className="img-reg-fluid rounded" 
                                                src={registerplayImage} 
                                                alt="Register Play"  />
                                            <i 
                                                className="fa fa-play-circle play-icon register-play-icon" 
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    ) : (
                                        <div>
                                            <img 
                                                className="img-reg-fluid rounded" 
                                                src={registerEmptyImage} 
                                                alt="Register Empty" />
                                        </div>
                                    )}
                                </div>
                                
                                <div className="col-md-6 ps-5">
                                <h1 className='weje-h1'>WEJE</h1>
                                <h2 className="h2-future-title fs-4"><Translate>THE FUTURE OF</Translate> DeFi <Translate>GAMING</Translate></h2>
                                <p className='p-future-text'>
                                <Translate>Join</Translate> WeJe <Translate>and experience online gaming on Polygon network! No deposits, no withdrawals, and no registrations! Connect with players worldwide and enjoy fast transactions, low fees, transparency and trust.</Translate>
                                </p>
                                <div className='appstore-grid'>
                                        <Register />
                                        <VideoComponent key={selectedVideo} videoType={selectedVideo} onClose={handleCloseVideo}/>
                                </div>

                                <div className={`advantages ${activeClass}`}>
                                <div className="item-overlay item-overlay-top"></div>
                                <div className="item-overlay item-overlay-bottom"></div>
                                <div className="item dapp" data-id="dapp" onMouseEnter={() => handleMouseEnter('dapp')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Real-time</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>Video chat</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>Real-time</Translate></span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span><Translate>Live Video & Audio Gaming</Translate></span></li>
                                                <li><span><Translate>Real Players Worldwide</Translate></span></li>
                                                <li><span><Translate>Join Weje Poker Community</Translate></span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div className="item secure" data-id="secure" onMouseEnter={() => handleMouseEnter('secure')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Secure</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>No deposit</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>Secure</Translate></span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span><Translate>No Deposits Needed</Translate></span></li>
                                                <li><span><Translate>No Registration Needed</Translate></span></li>
                                                <li><span><Translate>Encrypted WEB3 Transactions</Translate></span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div className="item decentralized" data-id="decentralized" onMouseEnter={() => handleMouseEnter('decentralized')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Decentralized</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>100% transparency</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>100% Decentralized</Translate></span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span><Translate>Peer to Peer Game</Translate></span></li>
                                                <li><span><Translate>No Transaction Intermediaries</Translate></span></li>
                                                <li><span><Translate>Control Over your Crypto</Translate></span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>                              

                                <div className="item blockchain" data-id="blockchain" onMouseEnter={() => handleMouseEnter('blockchain')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Blockchain</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>Polygon POL</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>Polygon Network</Translate></span>
                                            </div>
                                            <div className="content-text">
                                                <ol className="content-list">
                                                    <li><span><Translate>Ensures Transactions Applications Run Smoothly</Translate></span></li>
                                                    <li><span><Translate>You Need Polygon Matic Tokens To Play</Translate></span></li>
                                                    <li><span><Translate>Low Gas Fees</Translate></span></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>


                {/* Games Section */}
                <div className="how-section1">
                        <div className='section-title'>
                                <h4><Translate>GAMES</Translate></h4>
                            </div>
                    {games.map((game, index) => (
                        <div className="row games-row" key={index}>
                            {index % 2 === 0 && !isMobile? (
                                <>
                                    <div className="col-md-6 how-img">
                                        <img src={game.image} alt={game.title} />
                                    </div>
                                    <div className="col-md-6">
                                        <h3>{game.title}</h3>
                                        <h4 className="subheading"><Translate>{game.subheading}</Translate></h4>
                                        <p className="text-muted"><Translate>{game.description}</Translate></p>
                                        <button className='play-btn' onClick={handleRegisterPopup}><Translate>PLAY NOW</Translate></button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-md-6">
                                        <h3>{game.title}</h3>
                                        <h4 className="subheading"><Translate>{game.subheading}</Translate></h4>
                                        <p className="text-muted"><Translate>{game.description}</Translate></p>
                                        <button className='play-btn' onClick={handleRegisterPopup}><Translate>PLAY NOW</Translate></button>
                                    </div>
                                    <div className="col-md-6 how-img">
                                        <img src={game.image} alt={game.title} />
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>


                <div className='container'>
                <div className="row text-center">
                        <div className="col">
                            <div className='rigister-tab'>
                                <div className='center-grid'>
                                    <Register />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              
                {/* Counter data */}
                <div className="container count-container" ref={containerRef}>
                    <div className="row text-center">
                    {counters.map((counter) => (
                        <div className="col" key={counter.id}>
                        <div className="counter">
                            <h4><Translate>{counter.title}</Translate></h4>
                            <Counter to={counter.to} speed={counter.speed} format={counter.format} />
                            <p className="count-text"><Translate>{counter.text}</Translate></p>
                        </div>
                        </div>
                    ))}
                    </div>
                </div>


                <div className='container'>
                <div className='table-view'> 
                    <h2 className='community-title'><Translate>JOIN THE COMMUNITY</Translate></h2>
                    <table class="table table-dark">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col"><Translate>NAME</Translate></th>
                            <th scope="col"><Translate>PLAYER</Translate></th>
                            <th scope="col"><Translate>FRIENDS</Translate></th>
                            <th scope="col"><Translate>GAMES</Translate></th>
                            <th scope="col"><Translate>WIN RATIO</Translate></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th scope="row">1</th>
                            <td>Camilla</td>
                            <td><img src={brazil} alt="brazil" /> 0x264E....47A2E6</td>
                            <td>7</td>
                            <td>79</td>
                            <td>65%</td>
                            </tr>
                            <tr>
                            <th scope="row">2</th>
                            <td>Rizwan</td>
                            <td><img src={india} alt="india" /> 0x9062....3802Ba</td>
                            <td>12</td>
                            <td>115</td>
                            <td>82%</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>Keiko</td>
                            <td><img src={japan} alt="japan" /> 0x764e....3f285F</td>
                            <td>6</td>
                            <td>39</td>
                            <td>75%</td>
                            </tr>
                            <tr>
                            <th scope="row">4</th>
                            <td>Wong</td>
                            <td><img src={china} alt="china" /> 0x006E....1dC8Bd</td>
                            <td>23</td>
                            <td>137</td>
                            <td>33%</td>
                            </tr>
                            <tr>
                            <th scope="row">5</th>
                            <td>Diego</td>
                            <td><img src={mexico} alt="mexico" /> 0xA7Fe....3a85Fe</td>
                            <td>24</td>
                            <td>172</td>
                            <td>27%</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>


                {/* NFTs section */}
                <div className="nft-section">
                    <div className="container">
                        <div className="feeds-grid">
                        <div className="left-nft-section">
                            <h6>
                            <Translate>Play and subscribe to receive one</Translate> <span>NFT</span>{" "}
                            <Translate>from our collection as a gift. Limited to the first 1,000 users.</Translate>
                            </h6>
                            <button className="btn btn-dark offer-nft" onClick={handleNFTPopup} >
                            NFTs
                            </button>
                        </div>
                        <div className="right-nft-section">
                            <img src={nft} alt="feeds" />
                        </div>
                        </div>
                    </div>
                </div>

                <>
                <Modal
                    show={RegisterShow}
                    onHide={RegisterClose}
                    centered
                    className="register-popup-modal" >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                            <div className='container'>			
                                <div className='row'>
                                    <div className='col-md-12 mb-12'>
                                        <div className='register-popup-box signup-box'>
                                            <div className='login-form rigister-tab'>
                                                <img src={logo} alt="logo" className="title-mobile image-landing" />
                                                <div className='rigister-tab'>
                                                    <div className='appstore-grid'>
                                                        <Register />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </Modal.Body>
                </Modal>

          <Modal
            show={NFTPopup}
            onHide={handleNFTPopupClose}
            className="nft-popup"
            size="xl" >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
            <div className="container mt-4">
            <div className="row">
                {nftImages.map((image, index) => (
                <div key={index} className="col-md-4 mb-4 d-flex flex-column align-items-center">
                    <img src={image} alt={`NFT Panda ${index + 1}`} className="img-reg-fluid mb-2 rounded" />
                    <button className="btn btn-dark w-75 offer-btn" onClick={handleRegisterPopup}>
                        <Translate>Get Free Gift</Translate>
                    </button>
                </div>
                ))}
            </div>
            </div>

            </Modal.Body>
          </Modal>
            </>

            <div className="accordion-container mt-4">
                <h2 className="accordion-title mb-4 text-center">FAQ</h2>
                <Accordion defaultActiveKey="0" className="accordion">
                    {Accordions.map((accordion, index) => (
                    <Card key={index} className="accordion-card">
                        <Card.Header className="accordion-header">
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={`${index}`}
                            className="accordion-toggle" >
                                <Translate>{accordion.Item}</Translate>
                            <i className="fa fa-angle-down accordion-icon"></i>
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={`${index}`}>
                        <Card.Body className="accordion-body"><Translate>{accordion.Card}</Translate></Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    ))}
                </Accordion>
            </div>


            {/* dk-footer-box-info Section */}
            <div className="container home-footer">
                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className="dk-footer-box-info">
                        <h4><Translate>Follow us</Translate></h4>
                            <p className="footer-info-text">
                            <Translate>The Future of DeFi Social Gaming Platform.</Translate>
                            </p>
                            <div className="footer-social-link">
                            <ul>
                                <li>
                                <a href="https://www.instagram.com/weje_com/" aria-label="Follow us on Instagram">
                                    <i className="fa fa-instagram"></i>
                                </a>
                                </li>
                                <li>
                                <a href="https://www.facebook.com/wejecom" aria-label="Follow us on Facebook">
                                    <i className="fa fa-facebook"></i>
                                </a>
                                </li>
                                <li>
                                <a href="https://x.com/WejeGaming" aria-label="Follow us on Twitter">
                                    <i className="fa fa-twitter"></i>
                                </a>
                                </li>
                                <li>
                                <a href="https://t.me/wejedefi" aria-label="Follow us on Telegram">
                                    <i className="fa fa-telegram heteic"></i>
                                </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
     
                    <div className="col-md-12 col-lg-8">
                    <div className="row">

                    <div className="col-md-4">
                        <section className="contact-us-home contact-us-last">
                        <section className="footer-widget">
                            <div className="section-heading">
                                <h4>Polygon <Translate>Network</Translate></h4>
                            </div>
                            <div className='div-reg-pol'>
                            <img 
                                className="img-reg-pol" 
                                src={PolygonIcon} 
                                alt="polygon icon"  />
                            </div>
                            

                        </section>
                        </section>
                        </div>

                        <div className="col-md-4">
                        <section className="contact-us-home">
                            <section className="footer-widget">
                            <div className="section-heading">
                            <h4><Translate>Investors</Translate></h4>
                            </div>
                        
                            <Link to="/investment-opportunity">
                                <Translate>Help Us Grow</Translate>
                            </Link>

                        </section>
                        </section>
                        </div>

                    </div>
                </div>
            </div>
        </div>

          
            </Layout>
        </>
    );
};

export default Landing;
