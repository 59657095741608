import { useContext, useEffect, useState } from 'react';
import { isMobile } from "react-device-detect";
import { useWeb3React } from "@web3-react/core";
import { metaMask } from './metamask';
import NewAccount from '../signUp/newaccount';
import connecting from "../../assets/animation/connecting";
import metalogo from "../../assets/icons/MetaMask.png";
import coinbaselogo from "../../assets/icons/coinsbase.png";
import Lottie from "react-lottie";
import toast from "react-hot-toast";
import loading from "../../assets/animation/main_loader.json";
import "./crypto.css";
import axios from 'axios';
import firebase from '../../firebase';
import { MainContext } from '../../context';
import UrlConfig from "../../utils/ApiConfig";
import { Translate } from "react-auto-translate";
import { coinbaseWallet } from './coinbaseWallet';

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };
  const connectOptions = {
    loop: true,
    autoplay: true,
    animationData: connecting,
  };

  const ConnectButton = (props) => {
    const [state] = useState({
        xtkn: props.xtkn,
        deviceId: props.deviceId,
        ip: props.ip,
        isMobile: props.isMobile || isMobile,
        countryCode: props.countryCode,
        fmctoken: props.fmctoken || localStorage.getItem("fmcToken"),
    });
    const [checkMetaMaskInstall, setcheckMetaMaskInstall] = useState(false);
    const [checkCoinbaseInstall, setcheckCoinbaseInstall] = useState(false);
    const [walletName, setwalletName] = useState('');
    const [showaccount, setshowaccount] = useState(false);
    const { account, provider } = useWeb3React();
    const [connected, setConnected] = useState(false);
    const [idtoken, setidtoken] = useState('');
    const { firebaseUser, setBaseStates, setFirebaseUser, setToken } = useContext(MainContext);
    
    // Call this function only when login
        const handleLogin = async () => {
            toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
                id: "walletConnectAPI-loading",
                style: {
                    borderRadius: "15px",
                },
            });
        const res = await axios.get(UrlConfig.walletConnectAPI, {
                params: {
                isMobile: state.isMobile.toString(),
                countryCode: props.countryCode,
                widProvider: walletName,
                },
                headers: {
                device: props.deviceId || localStorage.getItem("deviceId"),
                ip: props.ip,
                walletid: account,
                fmcToken: props.fmcToken || localStorage.getItem("fmcToken"),
                idtoken: localStorage.getItem("idtoken"),
                },
                withCredentials: true,
                credentials: "include",
        });
            if (res.data.msg === "user logged in with same device" && res.data.goProfile) {
                //user goes to profile page;
                setBaseStates({
                    ...res.data.getDoc.doc,
                  uid: "",
                  doc: res.data.getDoc.doc,
                  userDoc: res.data.getDoc.userDoc,
                  inGame: res.data.getDoc.inGame,
                  inQue: res.data.getDoc.inQue,
                  currency: res.data.getDoc.currency,
                  datetimeNow: res.data.getDoc.datetimeNow
                });
    
            } else if (res.data.error === "nick required when wid NOT exists") {
                setshowaccount(true);
                toast.success(<span><Translate>Create your account</Translate></span>, {
                    duration: 4000,
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                    },
                });
            } else if ((res.data.error === "Auth-Token and wallet id mismatch")) {
                toast.error(<span><Translate>Please clear your browser's cache and cookies, then attempt again</Translate></span>, {
                    duration: 4000,
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                    },
                });
            } else if (res.data.error !== "no error") {
                console.log("res.data.error:" + res.data.error);
            } else {
                    // SUCCESS, user goes to profile page;
                    setBaseStates({
                        ...res.data.getDoc.doc,
                        uid: "",
                        doc: res.data.getDoc.doc,
                        userDoc: res.data.getDoc.userDoc,
                        inGame: res.data.getDoc.inGame,
                        inQue: res.data.getDoc.inQue,
                        currency: res.data.getDoc.currency,
                        datetimeNow: res.data.getDoc.datetimeNow
                    });
                    const currentUser = await firebase.auth().signInWithCustomToken(res.data.idtoken || localStorage.getItem("idtoken"))
                    const idToken = await currentUser.user.getIdToken(true);
                    setidtoken(idToken);
                    setToken(idToken);
                    setFirebaseUser(currentUser.user);
                    localStorage.setItem("idtoken", idToken);
                    localStorage.setItem("deviceId", props.deviceId);
                    localStorage.setItem("fmcToken", props.fmcToken);
                    localStorage.setItem("provider", walletName);
                    toast.success(
                        <span>
                          <Translate>Welcome to </Translate>
                          WEJE!
                        </span>,
                        {
                          duration: 4000,
                          style: {
                            maxWidth: 800,
                            fontSize: "14px",
                            borderRadius: "15px",
                          },
                        }
                      );
                    if(res.data.msg === "refresh idtoken, set local sotrage, and call CookieSetter" 
                    || res.data.msg === "login success" 
                    || res.data.msg === "call the cookie-setter-api") {
                    await axios.get(`${UrlConfig.cookieSetterAPI}`, {
                        headers: {
                            idtoken: idToken,
                            wid: account,
                        },
                        params: {
                            isLogout: "no"
                        },
                        withCredentials: true,
                        credentials: "include"
                    })
                    }
                   if (window.location.pathname === "/") {
                        window.location.href="/profile"
                    }
                }
            }
    
            function checkConnection() {
                if (window.ethereum) {
                    window.ethereum.request({ method: 'eth_accounts' }).then(handleAccountsChanged).catch(console.error);
                    function handleAccountsChanged(accounts) {
                        if (accounts.length === 0) {
                            localStorage.setItem('isWalletConnected', false);
                          } 
                        }
                        const isCoinbase = window.ethereum.isCoinbaseWallet;
                        const isMetaMask = window.ethereum.isMetaMask;
                        if (isCoinbase) {
                            setcheckCoinbaseInstall(true);
                        }  if (isMetaMask) {
                            setcheckMetaMaskInstall(true);
                        }
                    } else {
                        localStorage.setItem('isWalletConnected', false);
                    }
                }
    
                useEffect(() => {
                    checkConnection();
                    const getAccountData = async() => {
                        if (account) {
                            localStorage.setItem('isWalletConnected', true);
                            document.cookie = `walletid= ${account}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
                            localStorage.setItem('walletid', account);
                        }
                    }
                        if (!account) {
                            // handleConnectWallet();
                        } else if (account) {
                            getAccountData()
                        if (!firebaseUser && account) {
                            handleLogin();
                        }
                    }
                }, [account, provider, firebaseUser]);  
    
                async function handleConnectWallet(walletType) {
                    walletType = walletType || walletName; // Default to walletName if walletType is not provided
                    // Get the current origin from the URL 
                    const origin = window.location.origin;
                    if (!window.ethereum && isMobile) {
                        if (walletType === "MetaMask") {
                            return window.open(`https://metamask.app.link/dapp/${origin.replace(/^https?:\/\//, '')}`, '_blank');
                        } else if (walletType === "Coinbase") {
                            return window.open(`https://go.cb-w.com/dapp?cb_url=${origin}`, '_blank');
                        }
                    }
                        setConnected(true);
                        if (walletType === 'Coinbase') {
                            handleCoinbaseConnect();
                            return; // Exit after calling the Coinbase-specific function
                        }
                        if (walletType == 'MetaMask') { 
                            handleMetaMaskConnect();
                            return; // Exit after calling the Coinbase-specific function
                        }
                }
    
                async function handleCoinbaseConnect() {
                    try {
                        setwalletName('Coinbase');
                        await coinbaseWallet.activate({
                            chainId: 137, // Polygon Mainnet
                            chainName: "Polygon Mainnet",
                            nativeCurrency: {
                                name: 'Pol',
                                symbol: 'POL',
                                decimals: 18,
                            },
                            rpcUrls: ["https://polygon-rpc.com/"],
                            blockExplorerUrls: ["https://polygonscan.com/"]
                        });
                    } catch (error) {
                        console.error("Coinbase wallet connection error:", error);
                        if (error.code === 4001) {
                            // User rejected the request
                            console.log("Connection request was rejected.");
                        } else if (error.code === -32002) {
                            toast.loading(<span><Translate>Coinbase request pending..</Translate></span>, {
                                duration: 4000,
                                style: {
                                    maxWidth: 800,
                                    fontSize: "14px",
                                    borderRadius: "15px",
                                },
                            });
                        } else {
                            console.log("Unexpected error during Coinbase connection.");
                        }
                    }
                };

                async function handleMetaMaskConnect() {
                    if (window.ethereum) {
                        try {
                            setwalletName('MetaMask');
                            await metaMask.activate({
                                chainId: 137, // Polygon Mainnet
                                chainName: "Polygon Mainnet",
                                nativeCurrency: {
                                    name: 'Pol',
                                    symbol: 'POL',
                                    decimals: 18,
                                },
                                rpcUrls: ["https://polygon-rpc.com/"],
                                blockExplorerUrls: ["https://polygonscan.com/"]
                            });            
                            // Redirect to profile page if the user is authenticated
                            if (firebaseUser && window.location.pathname === "/") {
                                window.location.href = "/profile";
                            }
                        } catch (error) {
                            // Handle wallet connection errors
                            if (error.code === 4001) {
                            } else if (error.code === -32002) {
                                toast.loading(<span><Translate>MetaMask request pending..</Translate></span>, {
                                    duration: 4000,
                                    style: {
                                        maxWidth: 800,
                                        fontSize: "14px",
                                        borderRadius: "15px",
                                    },
                                });
                            } else {
                                console.error("Unexpected error:", error);
                            }
                        }
                    } else {
                        window.open("https://metamask.io/download/", "_blank");
                        localStorage.setItem('isWalletConnected', false);
                    }

                    
                }
    
            return (
                <>
                {connected && !showaccount? (
                    <div className="connectOptions">
                        <Lottie options={connectOptions} height={250} width={250} />
                    </div>
                ) : null}
                {account && showaccount ? (
                    <div>
                        <NewAccount
                        idtoken={idtoken}
                        walletid={account}
                        deviceId={state.deviceId}
                        xtkn={state.xtkn}
                        ip={state.ip}
                        isMobile={state.isMobile}
                        countryCode={state.countryCode}
                        fmctoken={state.fmctoken}
                        walletName={walletName}
                        />
                    </div>
                ) : 
                    <div>
                        <div className="container-connect-wallet">
                                <ul className="list">
                                    <li className="list-item">
                                        <button 
                                        className="wallet-btn"
                                        onClick={() => handleConnectWallet('MetaMask')} >
                                            <div>
                                            <img src={metalogo} className="metamasl-logo" alt="MetaMask" />
                                            </div>
                                            <div className="button-content">
                                                <span className="wallet-text-line">MetaMask</span>
                                                {checkMetaMaskInstall ? (
                                                <span className="label-text-line">Installed</span>
                                                ) : null}
                                            </div>
                                        </button>
                                    </li>
                                    <li className="list-item">
                                        <button 
                                            className="wallet-btn"
                                            onClick={() => handleConnectWallet('Coinbase')} >
                                            <div>
                                                <img src={coinbaselogo} className="metamasl-logo" alt="MetaMask" />
                                            </div>
                                            <div className="button-content">
                                                <span className="wallet-text-line">Coinbase</span>
                                                {checkCoinbaseInstall ? (
                                                <span className="label-text-line">Installed</span>
                                                ) : null}
                                            </div>
                                        </button>
                                    </li>
                                </ul>    
                            </div>
                    </div>
                }
            </>
      );        
    }
    
    export default ConnectButton;