import React from "react";
import ContactForm from "./SMTP";

const Trump = () => {
  
  return (
    <div className="tabbedcontent about-us">
        <h4>
          <b>Thinking to invest? </b> 
          Let’s schedule a meeting and discuss — <b className="email-invest">Support@weje.com</b>
        </h4>

      <div className="container">
        <div className="row">
          <div className="col-xs-12 crypto-view">
            <div className="card-crypto">
              <div className="card-body">

              <ul>
                  <li>
                    <strong>Now Is the Time for Crypto: &nbsp;</strong>
                    The recent U.S. elections have brought new momentum to the cryptocurrency industry, signaling a future where digital assets are increasingly positioned to play an important role in the global economy. With the crypto market now valued at $3.2 trillion—up from $2.4 trillion before the election—the stage is set for continued industry growth and new opportunities.
                  </li>

                  <li>
                    <strong>A Crypto-Friendly Administration: &nbsp;</strong>
                    The crypto community has renewed optimism following the election, with hopes for policies that encourage the adoption and innovation of digital assets. The incoming administration is expected to support blockchain advancements, paving the way for further industry expansion. This leadership shift brings new opportunities for blockchain advocates and crypto businesses alike.
                  </li>

                  <li>
                    <strong>Looking Ahead: &nbsp;</strong>
                    While once a skeptic of cryptocurrencies, President-elect Donald Trump now has significant backing from the crypto community. The real excitement, however, lies in what this election represents for the industry as a whole. Crypto companies and investors have actively supported candidates who align with their vision, contributing millions to various political campaigns. Trump’s personal cryptocurrency venture, World Liberty Financial, further highlights the growing support for blockchain technology.
                  </li>

                  <li>
                    <strong>Opportunities for Growth: &nbsp;</strong>
                    With the return of Trump to the White House, crypto advocates are hopeful that more regulatory clarity will emerge for digital assets. There is speculation that the administration could approve additional crypto-asset exchange-traded funds (ETFs), providing new ways for investors to access crypto markets. As government policies continue to align with blockchain innovation, there are growing opportunities for the expansion of Web3 technologies and decentralized finance.
                  </li>

                  <li>
                    <strong>Seize the Moment: &nbsp;</strong>
                    With the combination of political support, technological advancements, and market expansion, now is an ideal time to explore the blockchain industry. The U.S. is positioning itself to be a leading player in the global crypto revolution, offering new opportunities for both innovation and investment.
                  </li>
              </ul>

                <div className="form-component">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trump;
