import React, { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, Overlay, Tooltip } from "react-bootstrap";
import Layout from "../layout/layout";
import { Link, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import "./bet-list.css";
import { useWeb3React } from "@web3-react/core";
import SportsAbi from "../../ABI/SportsAbi.json";
import { ethers } from "ethers";
import { simplifyTicket } from "../../utils";
import CryptoNOT from "../cryptoNOT";
import Loader from "../loader/loader";
import { Translate } from "react-auto-translate";
import copyicon from "../../assets/images/profile/copyicon.svg";
import walletIcon from '../../assets/images/header/coin.png';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Share from "./share"

const SportsBetListing = () => {
  const [openBets, setOpenBets] = useState([]);
  const [runningBets, setRunningBets] = useState([]);
  const [finishedBets, setFinishedBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [walletConnected, setWalletConnected] = useState(true);
  const { account, provider } = useWeb3React();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareTicket, setShareTicket] = useState("");
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  useEffect(() => {
    if (account && provider) {
      setWalletConnected(true);
      const contract = new ethers.Contract(process.env.REACT_APP_SPORTS_CONTRACT_ADDRESS, SportsAbi, provider.getSigner());
      if (contract) {
        fetchTickets(contract);
      }
    } else if (!account) {
      setWalletConnected(false);
    }
  }, [account, provider]);

  const handleCopy = () => {
    setShowCopyTooltip(true);
    setTimeout(() => setShowCopyTooltip(false), 2000); // Hide tooltip after 2 seconds
    toast.success(<span><Translate>Copied</Translate></span>, {
      icon: "✔️",
      position: "top-right",
      style: {
        maxWidth: 800,
        fontSize: "14px",
        borderRadius: "15px",
      },
    });
  };

  const handleCloseShare = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Separate useEffect to handle the 'ticket' parameter
    const params = new URLSearchParams(location.search);
    const ticket = params.get("ticket");
    if (ticket) {
      setShareTicket(ticket);
      setIsModalOpen(true);
    }
  }, [location.search]);

  const fetchTickets = async (contract) => {
    setLoading(true);
    const openBets = await contract.getTickets(0);
    const runningBets = await contract.getTickets(1);
    const finishedBets = await contract.getTickets(2);
    setOpenBets(openBets.map(simplifyTicket));
    setRunningBets(runningBets.map(simplifyTicket));
    setFinishedBets(finishedBets.map(simplifyTicket));
    setLoading(false);
  };

  console.log("openBets", openBets);
  if (!walletConnected) {
    return <CryptoNOT />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout>
      <div className="sports-bet-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sports-list">
                <div className="sports-card">
                  <div className="sports-content">
                    <div className="row">
                      <div className="col-md-12">
                        <h6><Translate>Open Bets</Translate></h6>
                      </div>
                      <TableListWithPagination bets={openBets} betType="open" />
                    </div>
                  </div>
                </div>

                <div className="sports-bet-container">
                    <Modal
                      show={isModalOpen}
                      onHide={handleCloseShare}
                      centered
                      className="invite-friends-popup bet-popup" >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <h4 className="help-h4 bet-h4">
                            <img src={walletIcon} alt="info" className="share-matic" />
                            <Translate>Raise the ticket pool!</Translate>
                          </h4>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <div className="invite-friends-type invite-friends-bet">
                              <Share shareuserId={shareTicket} />
                            </div>
                        <div className="invite-bet-text">
                        <p>
                          <span style={{ fontSize: "16px" }}>
                            <Translate>Spread the word and challenge your friends</Translate>
                          </span> 
                        </p>
                        </div>
                        <div className="share-copy-link">
                          <InputGroup>
                            <Form.Control
                              defaultValue={`https://weje.com/sports-tickets/${shareTicket}`} />
                              <CopyToClipboard
                                text={`https://weje.com/sports-tickets/${shareTicket}`}
                                onCopy={handleCopy} >
                                <Button type="button">
                                  <img src={copyicon} alt="copy icon" />
                                </Button>
                              </CopyToClipboard>
                          </InputGroup>
                          <Overlay show={showCopyTooltip} placement="top">
                            {(props) => (
                              <Tooltip id="overlay-example" {...props}>
                                  <Translate>Copied</Translate>
                              </Tooltip>
                            )}
                          </Overlay>
                        </div>
                      </Modal.Body>
                    </Modal>
              </div>

                <div className="sports-card">
                  <div className="sports-content">
                    <div className="row">
                      <div className="col-md-12">
                        <h6><Translate>Running Bets</Translate></h6>
                      </div>
                      <TableListWithPagination bets={runningBets} betType="running" />
                    </div>
                  </div>
                </div>

                <div className="sports-card">
                  <div className="sports-content">
                    <div className="row">
                      <div className="col-md-12">
                        <h6><Translate>Finished Bets</Translate></h6>
                      </div>
                      <TableListWithPagination bets={finishedBets} betType="finished" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="copyright-text text-left">
            <p>
            <small>
              <Translate>Back to</Translate> <Link to="/profile"><Translate>profile</Translate></Link>
            </small>
            </p>
          </div>
        </div>

      </div>
    </Layout>
  );
};

export default SportsBetListing;

const TableListWithPagination = ({ bets = [], betType }) => {
  const handleBetClick = (betId) => {
    if (betType !== "running") {
      window.location.href = `/sports-tickets/${betId}`;
    }
  };

  return (
    <div className="col-md-12 ml-auto mr-auto">
      <div className="table-responsive">
        <table className="table betting-table">
          <tbody>
          <tr>
            <th><Translate>Bet Id</Translate></th>
            <th><Translate>Creatore</Translate></th>
            <th><Translate>Games</Translate></th>
            <th><Translate>Bet Amount</Translate></th>
            <th><Translate>Winning Pool</Translate></th>
            <th><Translate>Players</Translate></th>
            <th><Translate>Start Date</Translate></th>
            <th><Translate>End Date</Translate></th>
            <th><Translate>Status</Translate></th>
          </tr>
            {bets.length === 0 ? (
              <tr>
                <td colSpan="7" className="no-bet-found">No bets found</td>
              </tr>
            ) : (
              bets.map((item) => (
                <tr
                  key={item.betId}
                  onClick={() => handleBetClick(item.betId)}
                  role="presentation"
                  className={`betting-row ${betType === "running" ? "non-clickable" : ""}`} >
                  <td>{String(item.betId).slice(-10)}</td>
                  <td>{item.users[0].name}</td>
                  <td>{item.matches.length}</td>
                  <td>{item.amount}</td>
                  <td>{item.pool}</td>
                  <td>{item.users.length}</td>
                  <td>
                    {new Date(item.startDate).toLocaleDateString() +
                      ", " +
                      new Date(item.startDate).toLocaleTimeString()}
                  </td>
                  <td>
                    {new Date(item.endDate).toLocaleDateString() +
                      ", " +
                      new Date(item.endDate).toLocaleTimeString()}
                  </td>
                  <td
                    className={
                      item.status === 0
                        ? "purple"
                        : item.status === 1
                        ? "green"
                        : "red"
                      } >
                    {item.status === 0
                      ? <Translate>Open</Translate>
                      : item.status === 1
                      ? <Translate>Running</Translate>
                      : <Translate>Finished</Translate>}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* Hardcoded Pagination */}
      <div className="pagination-view">
        <ul className="pagination">
          <li className="page-item">
            <a className="page-link" href="#">
              &laquo;
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              1
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              2
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              3
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              4
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              5
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              &raquo;
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
