import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../layout/layout";
import "./join.css";
import { ethers } from "ethers";
import Loader from "../loader/loader";
import { MainContext } from "../../context";
import { useWeb3React } from "@web3-react/core";
import WejeAbi from "../../ABI/WejeAbi.json";
import BlackjackAbi from "../../ABI/BlackjackAbi.json";
import LudoAbi from "../../ABI/LudoAbi.json";
import Join from "./join";
import CryptoNOT from "../cryptoNOT/index";
import { convertToObject } from "../../utils";
import { Translate } from "react-auto-translate";

let viewMore = "no";
let interval1 = null;
let interval2= null;
let interval3 = null;

const JoinTable = () => {
  let { game } = useParams();
  const { token, firebaseUser: user, baseStats } = useContext(MainContext);
  const { account, provider } = useWeb3React();
  const history = useHistory();
  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState();
  const [walletConnected, setwalletConnected] = useState(localStorage.getItem("isWalletConnected"));
  const [selectedGame, setSelectedGame] = useState(game);
  const [gameName, setgameName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [adminPhoto, setadminPhoto] = useState(''); 
  const [tablelink, setTablelink] = useState(''); 
  const [gameStatus, setgameStatus] = useState(''); 
  const [buyin, setBuyin] = useState(); 
  const [minBet, setminBet] = useState(''); 
  const [media, setMedia] = useState(''); 
  const [playersLength, setplayersLength] = useState(''); 

  useEffect(()=>{
    let cont;
    if(account && provider){
      setLoading(true);
      const signer = provider.getSigner();
      let abi, contractAddress;
      if(game ==="blackjack"){
        abi = BlackjackAbi;
        contractAddress = process.env.REACT_APP_BLACKJACK_CONTRACT_ADDRESS
      }else if(game === "ludo"){
        abi = LudoAbi;
        contractAddress = process.env.REACT_APP_LUDO_CONTRACT_ADDRESS
      }else{
        abi = WejeAbi;
        contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
      }
      cont = new ethers.Contract(contractAddress, abi, signer);
      setContract(cont);
      interval1 =setTimeout(() => {
        fetchTablelist(cont);
      }, 3000);
      cont.on("GameCreated", () => {
        interval2 =setTimeout(() => {
          fetchTablelist(cont);
        },3000)
      });
      cont.on("PlayerLeft", () => {
        interval3 = setTimeout(() => {
          fetchTablelist(cont);
        },3000)
      });
    }
    return () => {
      if(cont){
        cont.off("GameCreated");
        cont.off("PlayerLeft");
      }
      if(interval1){
        clearInterval(interval1);
      }
      if(interval2){
        clearInterval(interval2);
      }
      if(interval3){
        clearInterval(interval3);
      }
    }
  },[account, provider, game])


  useEffect(() => {
    if(account){
      setwalletConnected(true);
    } else {
        setwalletConnected(false);
    }
}, [account])

  const fetchTablelist = async(cont) => {
    try {
      setSelectedGame(game);
    if(game === 'poker') {
        setgameName('Poker');
    }else if (game === 'blackjack' ) { 
      setgameName('Blackjack');
    }else if(game === "ludo"){
      setgameName("Ludo");
    }
    if(!cont && contract){
      return;
    }
    let contr =  cont || contract;
    setLoading(true);
    const games = await contr.getAllGames();
    let gameData = [];
    // Convert the response to an array of Game objects
 games.forEach(game => { 
  if(game[4].length === 10 || !game[8]){
    return;
  }
  gameData.push(convertToObject(game.gameId, game.gameType, game.minBet, game.autoHandStart, game.players, game.lastModified, game.admin, game.media, game.isPublic, game.rTimeout));
});

console.log(gameData);
    // gameType, isPublic, minBet, buyIn, autoHandStart
    const filteredGames = gameData.filter(game => game.isPublic);
   setTableList(filteredGames);
    setLoading(false);
  } catch(e) {
    setLoading(false);
   }
  }

  const handleShowTable = async (table) => { 
    setTablelink(`/${game}/index.html?tableid=${table.gameId}&gameCollection=${table.gameType}`);
    setadminPhoto(table.players.find(el => el.playerId === table.admin).photoUrl);
    setshowTable(!showTable);
    setgameStatus("lobby");
    setBuyin(table.buyIn);
    setminBet(table.minBet);
    setMedia(table.media);
    setplayersLength(table.players.length);
  }

  if (loading && walletConnected) {
    return <Loader />;
  } else if (!walletConnected) {
    return <CryptoNOT />;
  } else
    return (
      <Layout>
         {/* <button onClick={handleSend}>Send Ether</button> */}
        <div className="bet-las-vegas">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="weje-join-content">
                  <div className="select-game">
                    <p><Translate>Select a game</Translate></p>
                    <select
                      name="singleSelect"
                      onChange={(e) => { history.push(`/jointable/${e.target.value}`)}}
                      defaultValue={game}>
                      <option value="poker">Poker</option>
                      <option value="blackjack">Blackjack</option>
                      <option value="ludo">Ludo</option>
                      {/* 
                      <option value="backgammon">Backgammon</option>
                      */}
                    </select>
                  </div>
                  <div className="online-game-container">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6><Translate>Active games</Translate></h6>
                      </div>
                      {tableList && tableList.length ? (
                        tableList.map((table, i) => (
                          <div className="col-lg-4" key={`item-${i}`}>
                            <div className="our-team-main" onClick={() => handleShowTable(table)}>
                              <div className="team-front-weje" >
                                {table.media !== "no-media" ? (
                                  <div className="video-table">
                                    <i
                                      className={
                                        table.media === "video"
                                          ? "fa fa-video-camera"
                                          : "fa fa-microphone"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <img
                                  src={table.players.find(el => el.playerId === table.admin).photoUrl}
                                  alt=""
                                  className="img-fluid"
                                />
                                <h3 className="table-title"><Translate>{gameName}</Translate></h3>
                                <small className="admin-name">@{table.players.find(el => el.playerId === table.admin).name}</small>
                                {table.players.map((player, j) => (
                                  <div
                                    key={`item-${j}`}>
                                    {/* <img
                                      src={player.photoUrl}
                                      alt=""
                                      className="img-fluid"
                                    /> */}
                                  </div>
                                ))}
                              </div>
                              <div className="team-back-weje">
                                {table?.minBet && game === "poker" ? (
                                  <p>
                                    <small>Small blind:</small> 
                                    <span className="val-table">{table.minBet}</span>
                                    <br></br>
                                  </p>
                                ) : (
                                  ""
                                )}
								                {table?.minBet ? (
                                  <p>
                                    <small><Translate>Buy-in:</Translate></small> 
                                    <span className="val-table">{table.buyIn}</span>
                                    <br></br>
                                  </p>
                                ) : (
                                  ""
                                )}
                                <p>
                                <small><Translate>Players:</Translate></small> 
                                <span className="val-table">{table.players.length}</span>
                                <br></br>
                                </p>
                                {table?.watchers?.length ? (
                                  <p>
                                    <small><Translate>Watchers:</Translate></small> 
                                    <span className="val-table">{table.watchers.length}</span>
                                  </p>
                                ) : (
                                  ""
                                )}
                              
                              </div>
                              <div className="btn-show-div">
                                <Button
                                    onClick={() => handleShowTable(table)}
                                    className="btn-gold btn btn-primary btn-show">
                                <Translate>Join Game</Translate>
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-lg-12">
                          <div className="no-data-found">
                            <p><Translate>No games has been found</Translate></p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
				  {}
                  {tableList.length >= 9 ? (
                    <div className="pagination-view">
						          <Button className="btn-gold" onClick={() => fetchTablelist(game, 'yes', token)}><Translate>View More</Translate></Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <>
                {showTable ? (
                    <Join
                    minBet={minBet}
                    buyin={buyin}
                    status={gameStatus}
                    link={tablelink}
                    photo={adminPhoto}
                    game={selectedGame}
                    media={media}
                    length={playersLength}
                    contract={contract}
                    walletConnected={walletConnected}
                    setshowTable={setshowTable}
                    gameType={game}
                    />
                ) : (
                null
                )}
            </>
          </div>
        </div>
      </Layout>
    );
};

export default JoinTable;
