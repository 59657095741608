import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import EmailJS
import "./smtp.css";
import registerplayImage from "../../assets/images/register_play.png";
import VideoComponent from "../videos/video.jsx";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); 

  const handlePlayClick = (videoType) => {
    localStorage.setItem("language", "en");
    setIsVideoPlaying(true);
    setSelectedVideo(null); // Reset the video
    setTimeout(() => setSelectedVideo(videoType), 0); // Set the video type again after a short delay
  };

  const handleCloseVideo = () => {
    setIsVideoPlaying(false); // Reset to false
    setSelectedVideo(null); // Optionally reset the selected video if needed
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name && formData.email && formData.message) {
      const templateParams = {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      };

      emailjs
        .send(
          "service_pcslfxo", // Your service ID from EmailJS
          "template_49w53f9", // Your template ID from EmailJS
          templateParams,
          "2IyQtj6SUH2GlNikS" // Your user API key from EmailJS
        )
        .then(
          (response) => {
            console.log("Success:", response);
            setIsSubmitted(true);
          },
          (error) => {
            console.error("Failed:", error);
          }
        );
    } else {
      alert("Please fill in all the fields.");
    }
  };

  return (
    <div className="contact-form-wrapper">
    
            <div className="form-image-container" onClick={() => handlePlayClick("register")}>
              { !isVideoPlaying && ( 
                <>
                <img src={registerplayImage} alt="Contact Form" className="form-side-image" />
                <i className="fa fa-play-circle play-icon invest-play-icon" aria-hidden="true"></i>
                </>
              )}
            </div>      
         
      <div className="contact-form-container">
        <h2 className="contact-form-heading"><b>Let's have a call 👋</b></h2>
        {isSubmitted ? (
          <p className="success-message">Thank you for your message! We'll get back to you soon.</p>
        ) : (
          <form className="contact-form-onSubmit" onSubmit={handleSubmit}>
            <div className="form-group-name">
              <label htmlFor="name" className="form-label-name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-input-name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-email">
              <label htmlFor="email" className="form-label-email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-input-email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-message">
              <label htmlFor="message" className="form-label-message">Message:</label>
              <textarea
                id="message"
                name="message"
                className="form-textarea-message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="submit-button-form">Send</button>
          </form>
        )}
      </div>
      <VideoComponent key={selectedVideo} videoType={selectedVideo} onClose={handleCloseVideo}/>
    </div>
  );
};

export default ContactForm;
